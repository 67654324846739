module.exports = [{
      plugin: require('W:/projects/hrishi.me/node_modules/gatsby-remark-autolink-headers/gatsby-browser'),
      options: {"plugins":[],"icon":false},
    },{
      plugin: require('W:/projects/hrishi.me/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-129406122-1"},
    },{
      plugin: require('W:/projects/hrishi.me/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    }]
